import React, { useContext } from 'react'
import { Typography, Link } from '@material-ui/core'
import DataContext from '../../lib/data-context'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  linkWrapper: {
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-child)::after': {
        content: '" | "'
      }
    }
  }
}))

const LegalLinks = () => {
  const { officeId, legal } = useContext(DataContext)
  const classes = useStyles()

  if (!legal) {
    return null
  }

  const links = [{
    key: 'privacy',
    label: 'Datenschutzbestimmungen',
    href: `/legal/privacy/${officeId}`
  }, {
    key: 'terms',
    label: 'AGB',
    href: `/legal/terms/${officeId}`
  }, {
    key: 'disclaimer',
    label: 'Widerrufsbestimmungen',
    href: `/legal/disclaimer/${officeId}`
  }, {
    key: 'imprint',
    label: 'Impressum',
    href: `/legal/imprint/${officeId}`
  }]
    // Show only links to content, which is actually available
    .filter(_ => !!legal[_.key])
    .map(_ => (
      <span className={classes.linkWrapper} key={_.key}>
        <Link href={_.href} underline='none'>
          {_.label}
        </Link>
      </span>
    ))

  return (
    <Typography component='section' variant='body2' className={classes.root}>
      {links}
    </Typography>
  )
}

export default LegalLinks
