import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: ({ gutter }) => ({
    marginBottom: gutter && theme.spacing(3),
    textTransform: 'uppercase'
  })
}))

const SectionHeadline = ({ gutter = true, children }) => {
  const classes = useStyles({ gutter })
  return (
    <Typography variant='h6' className={classes.root}>
      {children}
    </Typography>
  )
}

export default SectionHeadline
