import React from 'react'
import { Box, Grid } from '@material-ui/core'
import SectionHeadline from '../../../../components/SectionHeadline'
import SectionNote from '../../../../components/SectionNote'
import Defects from './Defects'

const defectsProps = {
  serious: {
    title: 'Erhebliche Mängel',
    infoText: `
      Die Verkehrssicherheit ist beeinträchtigt! Nach Behebung dieser Mängel
      können Sie sicher sein, dass Ihr Fahrzeug wieder sicher & vorschriftsmäßig ist.
    `
  },
  minor: {
    title: 'Geringe Mängel',
    infoText: `
      Durch Behebung dieser Mängel erhöhen Sie die Sicherheit,
      Zuverlässigkeit und Wirtschaftlichkeit Ihres Fahrzeugs.
      Sie können zum Werterhalt Ihres Autos beitragen und den
      eigenen Geldbeutel schonen, indem Sie Verwarngelder bei
      Verkehrskontrollen vermeiden.
    `
  },
  additions: {
    title: 'Zusatzoptionen'
  }
}

const CostsBreakdown = ({ defects = [], onSelection, currency }) => {
  if (!defects.length) {
    return null
  }

  const defectsByKind = kind => defects.filter(_ => _.kind === kind)

  return (
    <Box component='section' my={5}>
      <SectionHeadline>Ihre Kostenaufstellung</SectionHeadline>
      <SectionNote>
        Wenn Sie einzelne Positionen nicht in Auftrag geben möchten,
        können Sie diese einfach abwählen. Die daraus resultierenden
        Gesamtkosten können Sie weiter unten einsehen.
      </SectionNote>
      <Grid container spacing={3}>
        <Defects
          currency={currency}
          defects={defectsByKind('serious')}
          onSelection={onSelection}
          {...defectsProps.serious}
        />
        <Defects
          currency={currency}
          defects={defectsByKind('minor')}
          onSelection={onSelection}
          {...defectsProps.minor}
        />
        <Defects
          currency={currency}
          defects={defectsByKind('addition')}
          onSelection={onSelection}
          {...defectsProps.additions}
        />
      </Grid>
    </Box>
  )
}

export default CostsBreakdown
