import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  appointmentData: {
    marginTop: '0.8rem',
    marginBottom: '0.8rem'
  }
}))

const Appointment = ({ appointment }) => {
  const classes = useStyles()

  return (
    <Box my={3}>
      <Typography variant='h5' className={classes.heading}>
        Ihre Wunschtermin Anfrage
      </Typography>
      <div className={classes.appointmentData}>
        {appointment.firstAppointmentWish && (
          <div>{new Date(appointment.firstAppointmentWish).toLocaleString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}{appointment.firstDaytime && (` - ${appointment.firstDaytime}`)}</div>
        )}
        {appointment.secondAppointmentWish && (
          <div>{new Date(appointment.secondAppointmentWish).toLocaleString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}{appointment.secondDaytime && (` - ${appointment.secondDaytime}`)}</div>
        )}
        {appointment.mobility === 'y' ? (
          <div><strong>Ja,</strong> ich benötige Mobilität</div>
        ) : (
          <div><strong>Nein,</strong> ich benötige keine Mobilität</div>
        )}
      </div>
      <div>
        Vielen Dank für Ihre Vorschläge. Wir schauen was wir für Sie tun können und setzen uns mit Ihnen in Verbindung.
      </div>
    </Box>
  )
}

export default Appointment
