import React from 'react'
import Mustache from 'mustache'
import { Box } from '@material-ui/core'
import Markdown from '../../../components/Markdown'

const Letter = ({ confirmationText = '', letterText = '', customer, user }) => {
  return (
    <Box mt={3}>
      <Markdown>
        {Mustache.render(confirmationText, {
          customer: {
            ...customer,
            hasEmail: Boolean(customer.email),
            email: `<span style="white-space: nowrap;">${customer.email}</span>`
          },
          user,
          isEvening: new Date().getHours() >= 17
        })}
      </Markdown>
    </Box>
  )
}

export default Letter
