const LOCALE = 'de-DE'

const defaultCurrency = new Intl.NumberFormat(LOCALE, {
  style: 'currency',
  currency: 'EUR'
})

const dateTime = new Intl.DateTimeFormat(LOCALE)

const formatCurrency = (value, currency) => {
  if (currency) {
    const officeCurrency = new Intl.NumberFormat(LOCALE, {
      style: 'currency',
      currency: currency
    })
    return officeCurrency.format(value)
  } else {
    return defaultCurrency.format(value)
  }
}

const formatDate = date => {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return dateTime.format(date)
}

export {
  defaultCurrency,
  dateTime,
  formatCurrency,
  formatDate
}
