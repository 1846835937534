import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Box, Container } from '@material-ui/core'
import DataContext from '../../lib/data-context'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.header,
    borderBottom: '1px solid #8E8E8E'
  },
  logo: {
    maxWidth: '100%'
  }
}))

const Header = () => {
  const classes = useStyles()
  const { officeInfo, theme } = useContext(DataContext)

  if (!officeInfo) {
    return null
  }

  const { headerLogo, headerLogoPosition } = theme

  return (
    <header className={classes.root}>
      <Container>
        <Box textAlign={headerLogoPosition}>
          <img
            className={classes.logo}
            src={headerLogo && headerLogo.data && headerLogo.data.full_url}
            alt='Logo'
          />
        </Box>
      </Container>
    </header>
  )
}

export default Header
