import React from 'react'
import { Box, makeStyles, Button } from '@material-ui/core'
import SectionHeadline from '../../../components/SectionHeadline'
import { SaveAlt } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  button: {
    marginBottom: theme.spacing(2)
  },
  label: {
    flex: 1,
    marginRight: '1em'
  }
}))

const Documents = ({ pdfs = [], documents = [], orderId }) => {
  if (!pdfs.length && !documents.length) {
    return null
  }

  const classes = useStyles()

  return (
    <Box my={5}>
      <SectionHeadline>Dokumente</SectionHeadline>
      <ul className={classes.list}>
        {pdfs.map(pdf => (
          <li key={pdf.id}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              href={`/api/documents/${pdf.id}/${pdf.name}/${orderId}/pdfs`}
              target='_blank'
              className={classes.button}
              fullWidth
            >
              <span className={classes.label}>{pdf.name}</span>
              <SaveAlt />
            </Button>
          </li>
        ))}
        {documents.map(document => (
          <li key={document.id}>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              href={document.document}
              target='_blank'
              className={classes.button}
              fullWidth
            >
              <span className={classes.label}>{document.name}</span>
              <SaveAlt />
            </Button>
          </li>
        ))}
      </ul>
    </Box>
  )
}

export default Documents
