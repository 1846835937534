import React from 'react'
import PoweredBy from './PoweredBy'
import OfficeInfo from './OfficeInfo'

const Footer = () => {
  return (
    <footer>
      <OfficeInfo />
      <PoweredBy />
    </footer>
  )
}

export default Footer
