import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const YouTube = () => (
  <SvgIcon viewBox='0 0 256 256'>
    <path d='M243.16,78.91a55.06,55.06,0,0,0-9.34-24.28,32.13,32.13,0,0,0-23.35-10.26C177.33,42,127.83,42,127.83,42h-.46s-49.49,0-82.65,2.34A34.29,34.29,0,0,0,20.91,54.63a55,55,0,0,0-9.35,24.28,395.51,395.51,0,0,0-2.33,39.68v18.67a384,384,0,0,0,2.33,39.68,58.15,58.15,0,0,0,9.35,24.75A37.58,37.58,0,0,0,47,212c18.67,1.89,80.29,2.33,80.29,2.33S176.81,213.9,210,212a31.45,31.45,0,0,0,23.38-10.32A56,56,0,0,0,242.7,177,383.66,383.66,0,0,0,245,137.29V118.14a325.15,325.15,0,0,0-1.89-39.22ZM166.59,125.6l-64,34.56V90.63l28.95,15.87Z' />
  </SvgIcon>
)

export default YouTube
