import { createMuiTheme } from '@material-ui/core/styles'

/**
 * Takes office specific theme data and returns a MUI theme
 * to be used with Material-UI.
 *
 * @param {Object} data Theme data for a certain office
 */
const createTheme = (data) => {
  let options = {}

  if (data) {
    options = {
      palette: {
        common: {
          signal: data.signal
        },
        primary: {
          main: data.primaryMain
        },
        secondary: {
          main: data.secondaryMain
        },
        background: {
          default: data.backgroundDefault,
          paper: data.backgroundPaper,
          header: data.backgroundHeader,
          footer: data.backgroundFooter,
          videoPreview: data.backgroundVideoPreview,
          letter: data.backgroundLetter
        },
        text: {
          primary: data.textPrimary,
          secondary: data.textSecondary,
          footer: data.textFooter,
          letter: data.textLetter
        }
      },
      props: {
        MuiLink: {
          color: 'inherit',
          underline: 'always'
        }
      },
      overrides: {
        MuiPaper: {
          root: {
            color: data.textSecondary
          }
        },
        MuiButton: {
          containedSecondary: {
            color: data.buttonText
          }
        }
      }
    }

    options = configureCustomFont(options, data)
  }

  return createMuiTheme(options)
}

const configureCustomFont = (options, data) => {
  const { fontName, fontRegular, fontBold } = data

  if (fontName) {
    const fontFace = []

    const buildFontFace = (fontWeight, url) => ({
      fontWeight,
      fontFamily: fontName,
      fontStyle: 'normal',
      fontDisplay: 'swap',
      unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
      src: `url('${url}') format('${getFileExtension(url)}')`
    })

    if (fontRegular) {
      fontFace.push(buildFontFace(400, fontRegular.data.full_url))
    }

    if (fontBold) {
      fontFace.push(buildFontFace(700, fontBold.data.full_url))
    }

    options = {
      ...options,
      typography: {
        ...options.typography,
        fontFamily: `${fontName}, Arial, Helvetica, sans-serif`
      },
      overrides: {
        ...options.overrides,
        MuiCssBaseline: {
          '@global': {
            '@font-face': fontFace
          }
        }
      }
    }
  }

  return options
}

const getFileExtension = url => url.split('.').pop()

export default createTheme
