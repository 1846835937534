import React from 'react'
import Footer from './Footer'
import { CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      // This is the color also for the PoweredBy footer part.
      // If the page content is not filling the whole window height
      // below the footer the color should be the same.
      backgroundColor: theme.palette.common.white
    }
  },
  content: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default
  }
}))

const Layout = ({ children }) => {
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <Header />
      <div className={classes.content}>
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
