import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Typography, Container, Box, Divider, Button } from '@material-ui/core'
import { KeyboardArrowLeftRounded } from '@material-ui/icons'
import DataContext from '../lib/data-context'
import Markdown from '../components/Markdown'
import NotFound from '../components/NotFound'

const headings = {
  terms: 'Allgemeine Geschäftsbedingungen',
  privacy: 'Datenschutzbestimmungen',
  imprint: 'Impressum',
  disclaimer: 'Widerrufsbestimmungen'
}

const Legal = ({ match, loadOffice }) => {
  const { legalKind } = match.params
  const { legal } = useContext(DataContext)

  if (!legal) {
    return <NotFound />
  }

  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <Container>
      <Box my={4} ml={-2}>
        <Button variant='text' onClick={handleBackClick}>
          <KeyboardArrowLeftRounded />
          Zurück
        </Button>
      </Box>
      <Box my={4}>
        <Typography variant='h5' component='h2' gutterBottom>
          {headings[legalKind]}
        </Typography>
        <Divider />
        <Box mt={2}>
          <Markdown>{legal[legalKind] || ''}</Markdown>
        </Box>
      </Box>
    </Container>
  )
}

export default withRouter(Legal)
