import React from 'react'
import { Typography, Container, Box } from '@material-ui/core'

const NotFound = () => (
  <Container maxWidth='sm'>
    <Box py={4}>
      <Typography variant='h3' gutterBottom>
        WIR KÖNNEN KEINE INFORMATION FÜR SIE ENTDECKEN!
      </Typography>
      <Typography>
        Der Auftrag wurde entweder gelöscht, archiviert oder die URL ist ungültig.
        Sollten Sie Fragen haben, wenden Sie sich bitte an Ihr Autohaus.
      </Typography>
    </Box>
  </Container>
)

export default NotFound
