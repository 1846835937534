import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: ({ gutter }) => ({
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(3)
  })
}))

const SectionNote = ({ children }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.root}>
      {children}
    </Typography>
  )
}

export default SectionNote
