import React from 'react'
import { Typography, Box, Link, Paper, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Phone, Email } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  photo: ({ user }) => ({
    height: 300,
    background: `url(/api/users/${user.id}/photo) center 30% / cover no-repeat`,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    [theme.breakpoints.up('md')]: {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
    }
  }),
  adressWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  address: {
    fontStyle: 'normal',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      paddingLeft: '10%',
      paddingRight: '10%'
    }
  },
  contactOption: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '2em'
  },
  contactIcon: {
    marginRight: theme.spacing(1)
  }
}))

const BusinessCard = ({ user }) => {
  const classes = useStyles({ user })
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} sm={5} md={4}>
          <div className={classes.photo} />
        </Grid>
        <Grid item xs={12} sm={7} md={8} className={classes.adressWrapper}>
          <Box component='address' className={classes.address} p={2}>
            <Typography variant='h6' component='div'>{user.firstname} {user.lastname}</Typography>
            <Typography variant='subtitle1' component='div'>{user.position}</Typography>
            <Box mt={2} fontSize='body1.fontSize'>
              {user.phone && (
                <div className={classes.contactOption}>
                  <Phone className={classes.contactIcon} />
                  <Link href={`tel:${user.phone}`}>{user.phone}</Link>
                </div>
              )}
              {user.email && (
                <div className={classes.contactOption}>
                  <Email className={classes.contactIcon} />
                  <Link href={`mailto:${user.email}`}>{user.email}</Link>
                </div>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default BusinessCard
