import React from 'react'
import { Box, Container } from '@material-ui/core'
import SectionHeadline from '../../../components/SectionHeadline'
import BusinessCard from '../../../components/BusinessCard'

const Contact = ({ user }) => (
  <Container component='section'>
    <Box my={5}>
      <SectionHeadline>Ihr Ansprechpartner</SectionHeadline>
      <BusinessCard {...{ user }} />
    </Box>
  </Container>
)

export default Contact
