import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Xing = () => (
  <SvgIcon viewBox='0 0 256 256'>
    <path d='M81.58,57.91H31.76L61.3,109.62l-39.24,65.1H71.87l39.25-65.1Zm101.1-41.55L104.22,154.41,155,243.52H208.1l-50.79-89.11,78.47-138Z' />
  </SvgIcon>
)

export default Xing
