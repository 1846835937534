import React from 'react'
import { Grid, Container } from '@material-ui/core'
import Video from '../../../../components/Video'
import Letter from './Letter'

const serviceSubtitles = {
  direct: 'Direktannahme-Protokoll',
  other: 'Service-Information'
}

const salesTitles = {
  new: 'Angebot Neuwagen',
  used: 'Angebot Gebrauchtwagen',
  other: 'Angebot'
}

const salesSubtitles = {
  introduction: 'Vorstellung',
  sale: 'Verkauf'
}

const Teaser = ({
  vehicle,
  purpose,
  section,
  orderId,
  videoId,
  preview,
  videoPath,
  posterPath,
  posterFallback,
  letterText,
  customer,
  user,
  alignedWidth = false
}) => {
  let videoTitle
  let videoSubtitle

  if (section === 'service') {
    videoTitle = vehicle.id
    videoSubtitle = serviceSubtitles[purpose] || serviceSubtitles['other']
  } else {
    videoTitle = salesTitles[vehicle.condition] || salesTitles['other']
    videoSubtitle = salesSubtitles[purpose] || salesSubtitles['other']
  }

  const content = (
    <Grid
      container
      component='section'
      alignItems='stretch'
    >
      <Grid item xs={12} md={6}>
        <Video
          orderId={orderId}
          videoId={videoId}
          videoPath={videoPath}
          posterPath={posterPath}
          preview={preview}
          posterFallback={posterFallback}
          title={videoTitle}
          subtitle={videoSubtitle}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Letter
          text={letterText}
          customer={customer}
          user={user}
        />
      </Grid>
    </Grid>
  )

  if (alignedWidth) {
    return (
      <Container>
        {content}
      </Container>
    )
  }

  return content
}

export default Teaser
