import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import DataContext from './lib/data-context'
import createTheme from './lib/theme'
import routes from './lib/routes'
import Layout from './components/Layout'
import NotFound from './components/NotFound'
import Order from './modules/Order'
import OrderConfirmation from './modules/OrderConfirmation'
import Legal from './modules/Legal'

const App = () => {
  const { error, theme } = useContext(DataContext)
  const officeTheme = createTheme(theme)

  if (error) {
    return <Route component={NotFound} />
  }

  return (
    <ThemeProvider theme={officeTheme}>
      <Layout>
        <Switch>
          <Route exact path={routes.order} component={Order} />
          <Route exact path={routes.orderConfirmation} component={OrderConfirmation} />
          <Route exact path={routes.legal} component={Legal} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </ThemeProvider>
  )
}

export default App
