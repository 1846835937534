import React from 'react'
import { makeStyles, Box, Typography, Grid, Divider } from '@material-ui/core'
import { formatDate } from '../../../../lib/intl-utils'

const useStyles = makeStyles(theme => ({
  licencePlateNumber: {
    textTransform: 'uppercase'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    margin: `.5em 0`,
    backgroundColor: theme.palette.primary.dark
  }
}))

const Summary = ({
  orderNumber,
  customer = {},
  licencePlateNumber,
  costs,
  lastModified
}) => {
  const classes = useStyles()

  return (
    <Box p={2} fontSize='body1.fontSize'>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <div>{customer.salutation} {customer.firstname} {customer.lastname}</div>
          <div>Auftragsnr.: {orderNumber}</div>
          <div>
            Kennzeichen: <span
              className={classes.licencePlateNumber}
            >
              {licencePlateNumber}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          {costs.serious !== null && (
            <div className={classes.item}>
              <span>Erhebliche Mängel</span>
              <strong>{costs.serious.toFixed(2)}</strong>
            </div>
          )}
          {costs.minor !== null && (
            <div className={classes.item}>
              <span>Geringe Mängel</span>
              <strong>{costs.minor.toFixed(2)}</strong>
            </div>
          )}
          {costs.addition !== null && (
            <div className={classes.item}>
              <span>Zusatzoptionen</span>
              <strong>{costs.addition.toFixed(2)}</strong>
            </div>
          )}
          <Divider className={classes.divider} />
          <div className={classes.item}>
            <strong>Gesamtpreis (inkl. MwSt.)</strong>
            <strong>{(costs.serious + costs.minor + costs.addition).toFixed(2)}</strong>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography gutterBottom>
            <strong>Stand: {formatDate(lastModified)}</strong>
          </Typography>
          <Typography>
            Die aufgeführten Preisangaben beinhalten die Lohn-Teilekosten.<br />
            Eine Kostenaufstellung erhalten Sie selbstverständlich in Ihrer E-Mail-Bestätigung.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Summary
