import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  play: {
    display: 'block',
    position: 'absolute',
    width: 70,
    height: 70,
    right: '1rem',
    bottom: '0',
    marginBottom: theme.spacing(2),
    borderRadius: '50%',
    background: `radial-gradient( ${theme.palette.common.signal} 60%, rgba(255, 255, 255, 1) 62%)`,
    boxShadow: `0px 0px 25px 3px ${theme.palette.common.signal}`,

    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
      position: 'relative',
      marginRight: theme.spacing(3),
      marginBottom: 0
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '50%',
      width: 0,
      height: 0,
      transform: 'translateX(-40%) translateY(-50%)',
      transformOrigin: 'center center',
      borderTop: '7px solid transparent',
      borderBottom: '7px solid transparent',
      borderLeft: '12px solid #fff',
      transition: 'all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',

      [theme.breakpoints.up('sm')]: {
        borderTopWidth: '15px',
        borderBottomWidth: '15px',
        borderLeftWidth: '25px'
      }
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-25%',
      left: '-25%',
      width: '150%',
      height: '150%',
      border: '5px solid rgba(255, 255, 255, 0.75)',
      borderRadius: '50%',
      opacity: 1,
      animation: '$pulsate 2s',
      animationDelay: '0s',
      animationIterationCount: 'infinite'
    }
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(0.6)',
      opacity: 1,
      boxShadow: 'inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75)'
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0,
      boxShadow: 'none'
    }
  }
}))

const PlayButton = ({ href, onClick }) => {
  const classes = useStyles()
  return (
    <div>
      <a
        href={href}
        target='_blank'
        onClick={onClick}
        className={classes.play}
        aria-label='Video abspielen'
      >
        {''}
      </a>
    </div>
  )
}

export default PlayButton
