import React from 'react'
import { makeStyles, Paper, Divider } from '@material-ui/core'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  divider: {
    margin: `0 ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.primary.dark
  },
  currency: {
    fontWeight: 'bolder',
    marginRight: '1rem',
    float: 'right'
  }
}))

const ItemBox = ({ title, infoText, children, currency }) => {
  const classes = useStyles()
  return (
    <Paper>
      <Header infoText={infoText} className={classes.header}>
        {title}
        <span className={classes.currency}>{currency}</span>
      </Header>
      {React.Children.toArray(children).reduce((items, child, index) => {
        if (index !== 0) {
          items.push(
            <Divider key={index} className={classes.divider} />
          )
        }
        items.push(child)
        return items
      }, [])}
    </Paper>
  )
}

export default ItemBox

export { default as Selectable } from './Selectable'
