import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Facebook = () => (
  <SvgIcon viewBox='0 0 256 256'>
    <path d='M147.89,242.08V138.4h35.4L188.35,98H147.89V72.66c0-11.37,3.79-20.22,20.22-20.22H189.6V15.77c-5,0-17.69-1.26-31.6-1.26A48.81,48.81,0,0,0,106.17,67.6V98H70.77V138.4h35.4V242.08Z' />
  </SvgIcon>
)

export default Facebook
