import App from './App'
import React, { useEffect } from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import DataContext from './lib/data-context'

const contextData = window.__INITIAL_DATA__ || {}

function Main () {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  return (
    <DataContext.Provider value={contextData}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DataContext.Provider>
  )
}

hydrate(<Main />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept()
}
