import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const LinkedIn = () => (
  <SvgIcon viewBox='0 0 256 256'>
    <path d='M128,12.85A115.15,115.15,0,1,0,243.15,128,115.14,115.14,0,0,0,128,12.85ZM84.83,205.9H52.48V101.78H84.83ZM68.65,87.55A18.76,18.76,0,1,1,87.36,68.73v0A18.76,18.76,0,0,1,68.65,87.55ZM206,205.9H173.7V155.26c0-12.08-.23-27.6-16.82-27.6-16.83,0-19.42,13.15-19.42,26.73V205.9H105.13V101.78h31V116h.44a34,34,0,0,1,30.62-16.82c32.74,0,38.81,21.56,38.81,49.6Z' />
  </SvgIcon>
)

export default LinkedIn
