import React from 'react'
import { Typography, Link } from '@material-ui/core'
import ReactMarkdown from 'markdown-to-jsx'

const markdownOptions = {
  overrides: {
    h1: { component: props => <Typography gutterBottom variant='h4' {...props} /> },
    h2: { component: props => <Typography gutterBottom variant='h5' {...props} /> },
    h3: { component: props => <Typography gutterBottom variant='h6' {...props} /> },
    h4: { component: props => <Typography gutterBottom variant='subtitle2' paragraph {...props} /> },
    p: { component: props => <Typography paragraph {...props} /> },
    a: { component: Link }
  }
}

const Markdown = (props) => <ReactMarkdown options={markdownOptions} {...props} />

export default Markdown
