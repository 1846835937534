import React from 'react'
import { IconButton } from '@material-ui/core'
import Facebook from '../icons/Facebook'
import Instagram from '../icons/Instagram'
import LinkedIn from '../icons/LinkedIn'
import Twitter from '../icons/Twitter'
import Xing from '../icons/Xing'
import YouTube from '../icons/YouTube'

const services = [{
  name: 'facebook',
  label: 'Facebook',
  Icon: Facebook
}, {
  name: 'instagram',
  label: 'Instagram',
  Icon: Instagram
}, {
  name: 'twitter',
  label: 'Twitter',
  Icon: Twitter
}, {
  name: 'youtube',
  label: 'YouTube',
  Icon: YouTube
}, {
  name: 'linkedin',
  label: 'LinkedIn',
  Icon: LinkedIn
}, {
  name: 'xing',
  label: 'Xing',
  Icon: Xing
}]

const Social = props => {
  return services
    .filter(({ name }) => !!props[name])
    .map(({ name, label, Icon }) => (
      <IconButton
        color='inherit'
        target='_blank'
        rel='noopener'
        key={name}
        href={props[name]}
        aria-label={label}
      >
        <Icon />
      </IconButton>
    ))
}

export default Social
