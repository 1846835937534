import React from 'react'

/**
 * Holds data for the whole application.
 * This is typically the intial data loaded on the server
 * and sent to the client with the document. This data
 * should not change during life cyle of the app on the client.
 */
const DataContext = React.createContext({})

export default DataContext
