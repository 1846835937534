import { withStyles } from '@material-ui/styles'
import { Tooltip as MuiTooltip } from '@material-ui/core'

const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'normal'
  }
}))(MuiTooltip)

export default Tooltip
