import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Instagram = () => (
  <SvgIcon viewBox='0 0 256 256'>
    <path d='M128,34.46a347.46,347.46,0,0,1,45.51,1.27A58.38,58.38,0,0,1,195,39.52,44.37,44.37,0,0,1,216.48,61a58.38,58.38,0,0,1,3.79,21.48c0,11.38,1.27,15.17,1.27,45.51a347.46,347.46,0,0,1-1.27,45.51A58.38,58.38,0,0,1,216.48,195,44.37,44.37,0,0,1,195,216.48a58.38,58.38,0,0,1-21.48,3.79c-11.38,0-15.17,1.27-45.51,1.27a347.46,347.46,0,0,1-45.51-1.27A58.38,58.38,0,0,1,61,216.48,44.37,44.37,0,0,1,39.52,195a58.38,58.38,0,0,1-3.79-21.48c0-11.38-1.27-15.17-1.27-45.51a347.46,347.46,0,0,1,1.27-45.51A58.38,58.38,0,0,1,39.52,61a45.1,45.1,0,0,1,8.85-12.64A21.35,21.35,0,0,1,61,39.52a58.38,58.38,0,0,1,21.48-3.79A347.46,347.46,0,0,1,128,34.46m0-20.22A372.53,372.53,0,0,0,81.23,15.5a78,78,0,0,0-27.81,5.06A49.45,49.45,0,0,0,33.2,33.2,49.45,49.45,0,0,0,20.56,53.42,57.62,57.62,0,0,0,15.5,81.23,372.53,372.53,0,0,0,14.24,128a372.53,372.53,0,0,0,1.26,46.77,78,78,0,0,0,5.06,27.81A49.45,49.45,0,0,0,33.2,222.8a49.45,49.45,0,0,0,20.22,12.64,78,78,0,0,0,27.81,5.06A372.53,372.53,0,0,0,128,241.76a372.53,372.53,0,0,0,46.77-1.26,78,78,0,0,0,27.81-5.06,53.08,53.08,0,0,0,32.86-32.86,78,78,0,0,0,5.06-27.81c0-12.64,1.26-16.43,1.26-46.77a372.53,372.53,0,0,0-1.26-46.77,78,78,0,0,0-5.06-27.81A49.45,49.45,0,0,0,222.8,33.2a49.45,49.45,0,0,0-20.22-12.64,78,78,0,0,0-27.81-5.06A372.53,372.53,0,0,0,128,14.24m0,55.61a57.22,57.22,0,0,0-58.15,56.26q0,.94,0,1.89A58.15,58.15,0,1,0,128,69.85h0m0,96.07a37.24,37.24,0,0,1-37.92-36.54q0-.69,0-1.38a37.24,37.24,0,0,1,36.54-37.92q.69,0,1.38,0a37.24,37.24,0,0,1,37.92,36.54q0,.69,0,1.38a37.24,37.24,0,0,1-36.54,37.92q-.69,0-1.38,0m60.67-112.5a13.91,13.91,0,1,0,13.91,13.91,13.9,13.9,0,0,0-13.91-13.91' />
  </SvgIcon>
)

export default Instagram
