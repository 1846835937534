import React from 'react'
import { Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { formatCurrency } from '../../../lib/intl-utils'

const useStyles = makeStyles(theme => ({
  licencePlateNumber: {
    textTransform: 'uppercase'
  },
  divider: {
    margin: `.5em 0`,
    backgroundColor: theme.palette.primary.dark
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  }
}))

const Summary = ({
  orderNumber = '',
  licencePlateNumber = '',
  totalCosts = 0,
  currency
}) => {
  const classes = useStyles()
  return (
    <Box my={3}>
      <div>Auftragsnr.: {orderNumber}</div>
      <div>
        Kennzeichen:{' '}
        <span className={classes.licencePlateNumber}>
          {licencePlateNumber}
        </span>
        <Divider className={classes.divider} />
        <div className={classes.total}>
          <span>Gesamtpreis (inkl. MwSt.)</span>
          <span>{formatCurrency(totalCosts, currency)}</span>
        </div>
      </div>
    </Box>
  )
}

export default Summary
