import React, { useContext } from 'react'
import { Container, Typography, Box, Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DataContext from '../../lib/data-context'
import Social from './Social'
import LegalLinks from './LegalLinks'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.footer,
    color: theme.palette.text.footer,
    borderBottom: '1px solid #8E8E8E'
  },
  preWrap: {
    whiteSpace: 'pre-wrap'
  },
  address: {
    fontStyle: 'normal'
  },
  logo: {
    maxWidth: 200,
    maxHeight: 100,
    marginBottom: theme.spacing(2)
  }
}))

const OfficeInfo = () => {
  const classes = useStyles()
  const { officeInfo, theme } = useContext(DataContext)

  if (!officeInfo) {
    return null
  }

  let { footerLogo } = theme
  footerLogo = footerLogo && footerLogo.data && footerLogo.data.full_url

  return (
    <div className={classes.root}>
      <Container>
        <Box py={4}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={7}
              md={5}
              component='address'
              className={classes.address}
            >
              {footerLogo && <img src={footerLogo} className={classes.logo} />}
              <Typography variant='body2' className={classes.preWrap}>
                {officeInfo.address}
              </Typography>

              <Box my={2}>
                {officeInfo.phone && (
                  <Typography variant='body2'>
                    Tel.: <Link href={`tel:${officeInfo.phone}`} underline='none'>
                      {officeInfo.phone}
                    </Link>
                  </Typography>
                )}
                {officeInfo.email && (
                  <Typography variant='body2'>
                    E-Mail: <Link href={`mailto:${officeInfo.email}`} underline='none'>
                      {officeInfo.email}
                    </Link>
                  </Typography>
                )}
                {officeInfo.website && (
                  <Typography variant='body2'>
                    <Link
                      href={officeInfo.website}
                      target='_blank'
                      rel='noopener'
                      underline='none'
                    >
                      {officeInfo.website.replace(/https?:\/\//, '')}
                    </Link>
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={5} md={4}>
              {officeInfo.openingHours && (
                <Typography variant='body2' className={classes.preWrap}>
                  {officeInfo.openingHours}
                </Typography>
              )}

              <Box mt={2} ml={-1}>
                <Social
                  facebook={officeInfo.facebook}
                  instagram={officeInfo.instagram}
                  twitter={officeInfo.twitter}
                  youtube={officeInfo.youtube}
                  linkedin={officeInfo.linkedin}
                  xing={officeInfo.xing}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <LegalLinks />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default OfficeInfo
