import React, { useContext } from 'react'
import DataContext from '../../lib/data-context'
import NotFound from '../../components/NotFound'
import AdBanner from '../../components/AdBanner'
import Teaser from './Teaser'

const OrderConfirmation = ({ match, loadOffice }) => {
  const { order, officeInfo, theme, content, preview, videoPath, posterPath, currency } = useContext(DataContext)

  if (!order || !officeInfo) {
    return <NotFound />
  }

  const totalCosts = (order.defects || []).reduce((sum, defect) => {
    if (defect.ordered) {
      sum += (defect.price.paket || 0.0) + defect.price.labor + defect.price.material
    }
    return sum
  }, 0)

  return (
    <div>
      <Teaser
        orderId={order.id}
        orderPdfs={order.pdfs}
        orderDocuments={order.documents}
        videoPath={videoPath}
        posterPath={posterPath}
        posterFallback={theme.videoPosterFallback}
        customer={order.customer}
        user={order.user}
        orderNumber={order.number}
        appointment={order.appointment}
        preview={preview}
        licencePlateNumber={order.vehicle && order.vehicle.id}
        totalCosts={totalCosts}
        currency={currency}
        confirmationText={content.orderConfirmationText}
      />
      <AdBanner image={officeInfo.adBanner} />
    </div>
  )
}

export default OrderConfirmation
