/**
 * Routes must be defined globally. They are not only used with
 * the (React) router but also on the server before render, to
 * determine which data to load upfront.
 */
export default {
  // TODO maybe /service/:orderId and /sales/:orderId
  order: '/order/:orderId',
  orderConfirmation: '/order/:orderId/confirmation',
  legal: '/legal/:legalKind/:officeId',
  card: '/card/:userId'
}
