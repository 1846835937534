import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, Box, Switch } from '@material-ui/core'
import { fade } from '@material-ui/core/styles'

const SESSION_KEY_PREFIX = 'Selectable.selected.'

const saveToSession = (inputValue, selected) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(
      SESSION_KEY_PREFIX + inputValue,
      selected ? '1' : ''
    )
  }
}

const loadFromSession = (inputValue) => {
  if (typeof sessionStorage !== 'undefined') {
    try {
      const selected = sessionStorage.getItem(SESSION_KEY_PREFIX + inputValue)
      if (selected !== null) {
        return !!selected
      }
    } catch (err) {}
  }
  return true
}

const useStyles = makeStyles(theme => ({
  main: ({ selected }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: !selected && 'line-through',
    opacity: !selected && 0.5
  }),
  titleArea: {
    flex: 1
  },
  comment: ({ selected }) => ({
    color: fade(theme.palette.text.secondary, 0.7),
    fontStyle: 'italic',
    textDecoration: !selected && 'line-through'
  }),
  switch: {
    marginRight: -theme.spacing(1)
  }
}))

const Selectable = ({
  title,
  subtitle,
  comment,
  secondary,
  inputValue,
  selected: defaultSelected = true,
  onChange = selected => {}
}) => {
  const [selected, setSelected] = useState(defaultSelected)
  const classes = useStyles({ selected })

  const firstRun = useRef(true)
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    saveToSession(inputValue, selected)
    onChange(selected)
  }, [selected])

  useEffect(() => {
    setSelected(loadFromSession(inputValue))
  }, [])

  const toggle = () => {
    setSelected(!selected)
  }

  return (
    <Box p={2} fontSize='body1.fontSize'>
      <div className={classes.main}>
        <div className={classes.titleArea}>
          <strong>{title}</strong><br />
          <span>{subtitle}</span>
        </div>
        <strong>{secondary}</strong>
        <Switch
          className={classes.switch}
          checked={selected}
          onChange={toggle}
          value={inputValue}
          inputProps={{ 'aria-label': title }}
        />
      </div>
      <div className={classes.comment}>
        {comment}
      </div>
    </Box>
  )
}

export default Selectable
