import React from 'react'
import { Box, makeStyles, Container } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    // otherwise image will be stretched
    alignItems: 'center'
  },
  img: {
    width: '100%'
  }
}))

const AdBanner = ({ image }) => {
  if (!image || !image.data || !image.data.full_url) {
    return null
  }

  const classes = useStyles()

  return (
    <Container component='section'>
      <Box my={5} pt={3} pb={1} className={classes.wrapper}>
        <img src={image.data.full_url} className={classes.img} />
      </Box>
    </Container>
  )
}

export default AdBanner
