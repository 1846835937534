import React, { useContext } from 'react'
import { Typography, Container, Box, makeStyles, Link } from '@material-ui/core'
import DataContext from '../../lib/data-context'

const positionToJustify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
  wrapper: ({ position }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: positionToJustify[position]
  }),
  logo: {
    height: '100%',
    maxHeight: 50
  }
}))

const PoweredBy = () => {
  const { theme } = useContext(DataContext)

  const {
    poweredByText,
    poweredByLink,
    poweredByLogo,
    poweredByPosition: position
  } = theme

  const classes = useStyles({ position })

  let logo
  if (poweredByLogo && poweredByLogo.data && poweredByLogo.data.full_url) {
    logo = <img src={poweredByLogo.data.full_url} className={classes.logo} alt='Logo' />
  }

  let boxClassName = classes.wrapper
  let content = (
    <>
      <Typography variant='caption'>{poweredByText}</Typography>
      {logo}
    </>
  )

  if (poweredByLink) {
    boxClassName = ''
    content = (
      <Link
        className={classes.wrapper}
        href={poweredByLink}
        underline='none'
        target='_blank'
        rel='noopener'
      >
        {content}
      </Link>
    )
  }

  return (
    <div className={classes.root}>
      <Container>
        <Box
          py={2}
          component='section'
          className={boxClassName}
        >
          {content}
        </Box>
      </Container>
    </div>
  )
}

export default PoweredBy
