import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Twitter = () => (
  <SvgIcon viewBox='0 0 256 256'>
    <path d='M81.07,236.07A148,148,0,0,0,230.4,89.4c0-.89,0-1.77,0-2.66V79.63a115.69,115.69,0,0,0,25.6-27,118.13,118.13,0,0,1-29.87,8.53A55.36,55.36,0,0,0,248.89,32.7a130.87,130.87,0,0,1-32.71,12.8,50.79,50.79,0,0,0-38.4-17.07,53.43,53.43,0,0,0-52.62,52.62,27.66,27.66,0,0,0,1.42,11.38A147,147,0,0,1,18.49,37a54.48,54.48,0,0,0-7.11,27,56.51,56.51,0,0,0,22.75,44.08A47.83,47.83,0,0,1,10,101h0a52,52,0,0,0,42.66,51.2,44,44,0,0,1-14.22,1.43,24.32,24.32,0,0,1-10-1.43,53.88,53.88,0,0,0,49.78,37A107.33,107.33,0,0,1,12.8,211.9,39.23,39.23,0,0,1,0,210.47a134.21,134.21,0,0,0,81.07,25.6' />
  </SvgIcon>
)

export default Twitter
